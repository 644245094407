// FIXME Move this to a global scss
.tooltip-inner {
  min-width: 380px;
}
.is-invalid [class*="control"] {
  border-color: #dc3545;
}

.vitola-modal .dropzone {
  width: auto;
  img {
    width: 100%;
  }
  .dz-details, .dz-success-mark, .dz-error-mark, .dz-error-message {
    display: none;
  }
}

.btn.btn-primary svg {
  padding: 0;
  margin: 0;
}
