/**=====================
     14. Chat CSS Start
==========================**/
.chat-box {
  .toogle-bar{
    display: none;
  }
  .chat-menu {
    max-width: 340px;
    .nav-material.nav-tabs {
      .nav-link.active {
        border-bottom: 1px solid $primary-color;
      }
      .nav-item.show {
        border-bottom: 1px solid $primary-color;
      }
      .nav-link {
        &:focus {
          border-bottom: 1px solid $primary-color;
        }
        &:hover {
          border-bottom: 1px solid $primary-color;
        }
      }
    }
    .people-list {
      height: 629px;
      overflow: auto;
      ul {
        li {
          cursor: pointer;
          &.active {
            background-color: rgba($primary-color, 0.2);
          }
          &:hover {
            background-color: $light-body-bg-color;
          }
        }
      }
    }
    .search{
      position: relative;
      .form-control{
        background-color: $light-body-bg-color;
        border: 1px solid $light-color;
        &::placeholder{
          color: $semi-dark;
        }
      }
      .form-group  {
        i {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 14px;
          color: $light-gray;
        }
      }
    }
    ul {
      padding: 0;
      li {
        cursor: pointer;
      }
    }
  }
  .people-list {
    .search{
      position: relative;
      .form-control{
        background-color: $light-body-bg-color;
        border: 1px solid $light-color;
        &::placeholder{
          color: $semi-dark;
        }
      }
      .form-group  {
        i {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 14px;
          color: $light-gray;
        }
      }
    }
    ul {
      padding: 0;
      li {
        cursor: pointer;
        padding: 10px 5px;
        &.active {
          background-color: rgba($primary-color, 0.2);
        }
        &:hover {
          background-color: $light-body-bg-color;
        }
      }
    }
  }
  .user-image {
    float: left;
    width: 52px;
    height: 52px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  .about {
    float: left;
    margin-top: 5px;
    padding-left: 10px;
    .name {
      color: $dark-color;
      letter-spacing: 1px;
      font-weight: 600;
    }
  }
  .status {
    color: $semi-dark;
    letter-spacing: 1px;
    font-size: 12px;
    margin-top: 5px;
    .chat-status{
      font-weight:600;
      color: $theme-body-font-color;
    }
    p{
      font-size:14px;
    }
  }
  .chat-right-aside{
    .chat {
      .chat-header {
        padding: 15px;
        border-bottom: 1px solid $light-color;
        img {
          float: left;
          width: 50px;
          height: 50px;
          box-shadow: 1px 1px 4px 1px $light-gray;
        }
        .chat-menu-icons{
          margin-top: 15px;
          li{
            margin-right:24px;
            a i{
              color: $theme-body-sub-title-color;
              font-size: 25px;
              cursor: pointer;
            }
          }
        }
      }
      .chat-msg-box {
        padding: 20px;
        overflow-y: auto;
        height: 550px;
        margin-bottom: 30px;
        .chat-user-img{
          margin-top: -35px;
        }
        .message-data {
          margin-bottom: 10px;
        }
        .message-data-time {
          letter-spacing: 1px;
          font-size: 12px;
          color: $semi-dark;
          font-family: $font-raleway;
        }
        .message {
          color: $dark-color;
          padding: 20px;
          line-height: 1.9;
          letter-spacing: 1px;
          font-size: 14px;
          margin-bottom: 30px;
          width: 50%;
          position: relative;
        }
        .my-message {
          border: 1px solid $light-color;
          border-radius: 10px;
          border-top-left-radius: 0;
        }
        .other-message {
          background-color: lighten($light-color  , 3%) ;
          border-radius: 10px;
          border-top-right-radius: 0;
        }
      }
      .chat-message {
        padding: 20px;
        border-top: 1px solid $light-body-bg-color;
        position: absolute;
        width: calc(100% - 15px);
        background-color: $white;
        bottom: 0;
        .smiley-box{
          background: $light-semi-gray;
          padding: 10px;
          display: block;
          border-radius: 4px;
          margin-right: 0.5rem;
        }
        .text-box{
          position: relative;
          .input-txt-bx {
            height: 50px;
            border: 2px solid $primary-color;
            padding-left: 18px;
            font-size: 12px;
            letter-spacing: 1px;
          }
          i{
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 20px;
            color: $light-gray;
            cursor: pointer;
          }
          .btn{
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .chat-menu {
    border-left: 1px solid $light-color;
    .tab-pane {
      padding: 0 5px;
      height: 629px;
      overflow: auto;
    }
    ul{
      li{
        .about{
          .status{
            i{
              font-size: 10px;
            }
          }
        }
      }
    }
    .user-profile {
      margin-top: 30px;
      .user-content{
        h5{
          margin: 25px 0;
        }
        hr{
          margin: 25px 0;
        }
        p{
          font-size: 16px;
        }
      }
      .image {
        position: relative;
        .icon-wrapper {
          position: absolute;
          bottom: 0;
          left: 55%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 35px;
          width: 35px;
          border-radius: 50%;
          background-color: $white;
          cursor: pointer;
          overflow: hidden;
          margin: 0 auto;
          font-size: 14px;
          box-shadow: 1px 1px 3px 1px $light-color;
        }
        .avatar img {
          border-radius: 50%;
          border: 5px solid $light-color;
        }
      }
      .border-right {
        border-right: 1px solid $light-color;
      }
      .follow {
        margin-top: 0;
        .follow-num {
          font-size: 22px;
          color: $black;
        }
        span {
          color: $theme-font-color;
          font-size: 14px;
          letter-spacing: 1px;
        }
      }
      .social-media a {
        color: $semi-dark;
        font-size: 15px;
        padding: 0 7px;
      }
      .chat-profile-contact{
        p{
          font-size:14px;
          color:$semi-dark;
        }
      }
    }
    .nav{
      margin-bottom: 20px;
    }
    .nav-tabs .nav-item {
      width:33.33%;
      a {
        padding: 15px!important;
        color: $semi-dark!important;
        letter-spacing: 1px;
        font-size: 14px;
        font-weight: 600;
        height: 80px;
        display:flex;
        align-items:center;
        justify-content:center;
        cursor: pointer;
      }
      .material-border{
        border-width:1px;
        border-color:$primary-color;
      }
      .nav-link.active {
        color: $black!important;
      }
    }
  }
  .chat-history{
    .call-content {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 300px;
    }
    .total-time h2 {
      font-size: 50px;
      color: $light-semi-gray;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .receiver-img{
      margin-top: 55px;
      img{
        border-radius: 5px;
      }
    }
    .call-icons{
      margin-bottom: 35px;
      ul{
        li {
          width: 60px;
          height: 60px;
          border: 1px solid $light-color;
          border-radius: 50%;
          padding: 12px;
          + li{
            margin-left: 10px;
          }
          a {
            color: #999;
            font-size: 25px;
          }
        }
      }
    }
  }
}
.chat-left-aside{
  > .media{
    margin-bottom: 15px;
  }
  .people-list{
    height: 625px;
    overflow: auto;
  }
  ul li{
    position: relative;
  }
  .status-circle{
    width: 10px;
    height: 10px;
    position: absolute;
    top:50px;
    left:46px;
    border-radius: 50%;
    border:1px solid $white;
  }
  .away{
    background-color: $warning-color;
  }
  .online{
    background-color: $success-color;
  }
  .offline{
    background-color: $danger-color;
  }
}
.chat-container{
  .aside-chat-left{
    width:320px;
  }
  .chat-right-aside{
    width:320px;
  }
}
.call-chat-sidebar{
  max-width: 320px;
}
.call-chat-sidebar,.chat-body{
  .card{
    .card-body{
      padding: 15px;
    }
  }
}
/**=====================
    14.  Chat CSS Ends
==========================**/
