//.cropper-crop-box {
//  background-color: #000 !important;
//}
.cropper-bg {
  //background: #000 !important;
  //height: 500px !important;
}
.cropper-point.point-se {
  bottom: -10px;
  right: -10px;
  border-radius: 50%;
}

.circle-crop {
  .cropper-crop-box, .cropper-view-box {
    border-radius: 50%;
  }

  .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
  }
}
