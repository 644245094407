.dropzone {
  height: 124px;
  min-height: 124px;
  //width: 124px;
  width: 100%;
  border-color: #dfdfdf;
  border-style: dashed;
  color: #6f6f6f;
  border-radius: 5px;
  margin: 10px 15px;
  cursor: pointer;
}

.product-images {
  .dropzone {
    width: 124px;
  }
}

.dz-message {
  padding: 20px;
}

.dropzone:hover {
  border-color: rgb(211, 169, 102);
}

.dz-details {
  display: table-cell;
  vertical-align: middle;
  height: 120px;
  width: 120px;
  padding: 0;
}

.dz-progress {
  position: relative;
  top: -70px;
  .progress-bar {
    border-radius: 10px;
  }
}

.modal-dialog-xs {
  max-width: 350px;
  min-width: 350px;

  .modal-dialog, .modal-content, .modal-body {
    min-width: 350px;
    border: none;
  }
}
.modal-dialog-sm {
  max-width: 600px;
  .modal-dialog, .modal-content, .modal-body {
    min-width: 600px;
    border: none;
  }
}
.modal-dialog-md {
  max-width: 800px;
  .modal-dialog, .modal-content, .modal-body {
    min-width: 800px;
    border: none;
  }
}
.modal-dialog-lg {
  max-width: 1024px;
}

.modal-body {
  background-color: rgba(246, 246, 246, 0.6);
}

.card {
  border: 1px solid rgb(223, 223, 223);
}

.image-overlay {
  opacity: 0;
  border-radius: 5px;
  border: 1px solid rgb(223, 223, 223);
  position: absolute;
  top: 0;
  width: 124px;
  height: 100%;
  background: linear-gradient(180deg,rgba(33,43,54,.55),hsla(0,0%,100%,0));
}

.image-overlay:hover {
  opacity: 1;
}

.expand-icon {
  color: #ffffff;
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}

.delete-image-icon {
  color: #ffffff;
  position: absolute;
  top: 5px;
  right: 5px;
}

.alert-warning.suggestions {
  color: #856404 !important;
  background-color: #fff3cd !important;
  border-color: #ffeeba !important;
}

.avatar-contained img {
  object-fit: contain !important;
}

.select-wrapper > div {
  z-index: 1000;
}

.carousel-indicators {
  bottom: 0;
}

.carousel-indicators .active {
  background-color: #856404;
}

.carousel-indicators li {
  background-color: #856404;
  opacity: 0.5;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23856404' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23856404' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-prev, .carousel-control-next {
  width: 10%;
}
