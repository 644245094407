/**=====================
     34. Pricing CSS Start
==========================**/
%pricing-position {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid $primary-color;
  border-top-color: transparent;
  border-left-color: transparent;
}
.pricing-wrapper-card {
  padding: 50px 100px;
  .pricing-card {
    background: $white;
    border-radius: 3px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.11);
    flex: 1;
    margin: 8px;
    padding: 30px;
    position: relative;
    text-align: center;
    transition: all 0.5s ease-in-out;
    &.popular {
      margin-top: -10px;
      margin-bottom: -10px;
      .card-price {
        margin: 50px;
        h1 {
          color: $primary-color;
          font-size: 100px;
        }
      }
      .card-title h3 {
        color: $primary-color;
        font-size: 22px;
      }
      .card-action button {
        background-color: $primary-color;
        border-radius: 80px;
        color: $white;
        font-size: 17px;
        margin-top: -15px;
        padding: 15px;
        height: 80px;
        &:hover {
          background-color: $primary-color;
        }
      }
    }
    &:hover {
      box-shadow: 6px 5px 17px 2px rgba(0, 0, 0, 0.08);
    }
  }
  .card-ribbon {
    position: absolute;
    overflow: hidden;
    top: -10px;
    left: -10px;
    width: 114px;
    height: 112px;
    span {
      position: absolute;
      display: block;
      width: 160px;
      padding: 10px 0;
      background-color: $primary-color;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      color: $white;
      font-size: 13px;
      text-transform: uppercase;
      text-align: center;
      left: -35px;
      top: 25px;
      transform: rotate(-45deg);
    }
  }
  .card-ribbon::before {
    @extend %pricing-position;
    top: 0;
    right: 0;
  }
  .card-ribbon::after {
    @extend %pricing-position;
    bottom: 0;
    left: 0;
  }
  .card-title {
    h3 {
      color: rgba(0, 0, 0, 0.3);
      font-size: 15px;
      text-transform: uppercase;
    }
    h5 {
      color: rgba(0, 0, 0, 0.6);
      margin-top: 15px;
      line-height: 1.8;
    }
  }
  .card-price {
    margin: 60px 0;
    h1 {
      font-size: 80px;
      sup {
        font-size: 15px;
        display: inline-block;
        margin-left: -20px;
        width: 10px;
      }
      small {
        color: rgba(0, 0, 0, 0.3);
        display: block;
        font-size: 11px;
        text-transform: uppercase;
      }
    }
  }
  .card-description {
    ul {
      display: block;
      list-style: none;
      margin: 60px 0;
      padding: 0;
    }
    li {
      color: rgba(0, 0, 0, 0.6);
      font-size: 15px;
      margin: 0 0 15px;
    }
    li::before {
      font-family: $font-awesome;
      content: "\f00c";
      padding: 0 5px 0 0;
      color: rgba(0, 0, 0, 0.15);
    }
  }
  .card-action button {
    background: transparent;
    border: 2px solid $primary-color;
    border-radius: 30px;
    color: $primary-color;
    cursor: pointer;
    display: block;
    font-size: 15px;
    font-weight: bold;
    padding: 20px;
    width: 100%;
    height: 60px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: $primary-color;
      box-shadow: 0 2px 4px $primary-color;
      color: $white;
    }
    &:focus{
      outline: none !important;
    }
  }
}
/**=====================
    34. Pricing CSS Ends
==========================**/