@import "../../assets/scss/variables";

.rui-dropzone {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    cursor: pointer;
    background-color: #fbfcfc;
    border: 3px dashed #e6ecf0;
    border-radius: .25rem;
    transition: border-color .2s ease-in-out;

    &:hover {
        border-color: $color_grey_3;
    }

    &.rui-dropzone-is-active {
        border-color: lighten($color_brand, 10%);
    }

    .rui-dropzone-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 150px;
        margin: -5px 0;
    }
    .rui-dropzone-icon {
        margin-bottom: 20px;
        color: $color_grey_4;

        > .rui-icon {
            width: 50px;
            height: 50px;
        }
    }
    .rui-dropzone-text {
        font-size: 1.29rem;
        color: $color_secondary;
    }

    @include media-breakpoint-down(xs) {
        min-height: 100px;

        .rui-dropzone-icon > .rui-icon {
            width: 40px;
            height: 40px;
        }
        .rui-dropzone-text {
            font-size: 1rem;
        }
    }
    .rui-dropzone-preview {
        position: relative;
        flex: 0 0 16.6%;
        max-width: 16.6%;
        padding: 15px;
        margin: 0;

        @include media-breakpoint-down(lg) {
            flex: 0 0 20%;
            max-width: 20%;
        }
        @include media-breakpoint-down(sm) {
            flex: 0 0 33.3%;
            max-width: 33.3%;
        }
        @include media-breakpoint-down(xs) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .rui-dropzone-remove {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 25px;
            right: 25px;
            width: 16px;
            height: 16px;
            color: $color_grey_5;
            background-color: #fff;
            border: none;
            border-radius: .2rem;
            opacity: 0;
            transition: opacity .2s ease-in-out, color .1s ease-in-out;
            z-index: 100;

            > .rui-icon {
                width: 14px;
                height: 14px;
                cursor: pointer;
            }
            > .rui-icon > line {
                cursor: pointer;
            }
            &:hover {
                color: $color_secondary;
            }
        }
        .rui-dropzone-image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: auto;
            background: none;
            background-color: #fff;
            border: 1px solid $color_grey_2;
            border-radius: .25rem;
            transition: background-color .15s ease-in-out, border-color .15s ease-in-out;

            &::before {
                content: "";
                display: block;
                margin-top: 100%;
            }
        }
        .rui-dropzone-details {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 0;
            opacity: 1;

            .rui-dropzone-size {
                display: flex;
                order: 1;
                margin: 4px 0 -5px;
                font-size: 11px;
                color: $color_grey_5;

                > span {
                    padding: 0;
                    background-color: transparent;
                }
                strong {
                    font-weight: 400;
                }
            }
            .rui-dropzone-filename {
                display: flex;
                order: 0;
                margin-top: 10px;
                overflow: hidden;
                color: $color_secondary;

                > span {
                    padding: 0;
                }

                &:not(:hover) span {
                    border: 0;
                }
                &:hover span {
                    border: 0;
                }
            }
        }

        &:hover {
            .rui-dropzone-remove {
                opacity: 1;
            }
            .rui-dropzone-image {
                border-color: $color_grey_3;
            }
        }
    }
}
