/**=====================
    03.  Card CSS Start
==========================**/
.card{
  .card-header{
    .card-header-right{
      border-radius: 0 0 0 7px;
      right: 20px;
      top: 23px;
      display: inline-block;
      float: right;
      padding: 7px 0;
      position: absolute;
      .card-option{
        text-align: right;
        width: 35px;
        height: 20px;
        overflow: hidden;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        li{
          display: inline-block;
        }
      }
      i{
        margin: 0 5px;
        cursor: pointer;
        color: $dark-color;
        line-height: 20px;
        &.icofont-refresh{
          font-size: 13px;
        }
      }
    }
  }
}
.card{
  &.card-load {
    .card-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 999;
      align-items: center;
      justify-content: center;
      i{
        margin: 0 auto;
        color: $primary-color;
        font-size: 20px;
      }
    }
  }
}
.card{
  &.full-card {
    position: fixed;
    top: 80px;
    z-index: 99999;
    -webkit-box-shadow: none;
    box-shadow: none;
    right: 0;
    border-radius: 0;
    border: 1px solid $light-semi-gray;
    width: calc(100vw - 270px);
    height: calc(100vh - 80px);
  }
}
.card-absolute{
  margin-top: 20px;
  .card-header{
    position: absolute;
    top: -20px;
    margin-bottom: 30px;
    left: 15px;
    border-radius: 0.25rem;
    padding: 10px 15px;
    h5{
      font-size: 17px;
    }
  }
  .card-body{
    margin-top: 10px;
  }
}
.card-header{
  .nav-material{
    margin-bottom: -13px;
  }
}
.custom-card{
  overflow: hidden;
  padding: 30px;
  .card-header{
    padding: 0;
    img{
      border-radius: 50%;
      margin-top: -100px;
      transform: scale(1.5);
    }
  }
  .card-profile{
    text-align: center;
    img{
      height: 150px;
      padding: 7px;
      background-color: $white;
      z-index: 1;
      position: relative;
    }
  }
  .card-social{
    text-align: center;
    li{
      display: inline-block;
      padding: 15px 0;
      a{
        padding: 13px;
        color: $light-semi-gray;
        font-size: 16px;
        transition: all 0.3s ease;
        &:hover{
          color: $primary-color;
          transition: all 0.3s ease;
        }
      }
    }
  }
  .profile-details{
    h6{
      margin-bottom: 30px;
      margin-top: 10px;
      color:$theme-body-sub-title-color;
      font-size: 14px;
    }
  }
  .card-footer{
    padding: 0;
    > div{
      +div{
        border-left: 1px solid $light-semi-gray;
      }
      padding: 15px;
      text-align: center;
      h3{
        margin-bottom: 0;
        font-family: $font-open-sans;
        font-size: 24px;
      }
      h6{
        font-size: 14px;
        color:$theme-body-sub-title-color;
      }
      i{
        font-size: 24px;
        display: inline-block;
        margin-bottom: 15px;
      }
      .m-b-card{
        margin-bottom: 10px;
      }
    }
  }
}
/**=====================
    03. Card CSS End
==========================**/