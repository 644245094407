.linked-cigar-block .row {
    background-color: #f1f1f1;
    padding: 10px;
    border-left: 1px solid #d1d1d1;
    border-right: 1px solid #d1d1d1;
}
.linked-cigar-block .row:first-of-type {
    background-color: #f1f1f1;
    border-radius: 5px 5px 0 0;
    padding: 10px;
    border: 1px solid #d1d1d1;
    border-bottom: none;
}
.linked-cigar-block .row:nth-last-child(2) {
    border-bottom: 1px solid #d1d1d1;
    border-radius: 0 0 5px 5px;
}
.linked-cigar-block .row:last-of-type {
    background-color: transparent;
    border: none;
}