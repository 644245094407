/**=====================
     23. Progress CSS Start
==========================**/
.progress-showcase{
	.progress{
		margin-bottom: 10px;
		&:last-child{
			margin-bottom: 0;
		}
    }	
}
.sm-progress-bar{
	height:6px;
}
.lg-progress-bar{
	height:20px;
}

/**=====================
     23. Progress CSS Ends
==========================**/