.circle-thumbnail {
  .thumbnail {
    img {
      width: 100px;
      height: 100px;
      max-width: 100px !important;
      border-radius: 50%;
      border: 4px solid rgb(255, 255, 255);
      box-shadow: rgb(149, 149, 149) 0px 1px 8px;
    }
  }
}