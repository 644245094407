th.sortable {
  cursor: pointer;
  .react-bootstrap-table-sort-order {
    .caret:before {
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 14px;
      margin-left: 5px;
      content: "\f0d8"
    }
    &.dropup {
      .caret:before {
        content: "\f0d7"
      }
    }
  }
}

.modal-dialog,
.modal-content,
.modal-body {
  min-width: 600px;
}

@media screen and (max-width: 766px) {
  .modal-dialog {
    width: 100%;
    min-width: 100%;
    height: 100%;
    margin: 0;

    .modal-content {
      height: 100%;
      min-width: 100%;
      border-radius: 0;
      border: none;

      .modal-body {
        min-width: 100%;
        max-height: calc(100% - 134px);
        overflow: scroll;
      }
    }
  }
  .modal-body.md {
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

.padded-multiline {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.form-control.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.similar-cigar-search {
  min-width: calc(100% - 70px);
  margin-left: 12px;
}

button:focus {
  outline: none;
}

// FIXME Where are these actually set???
.btn-primary {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
.btn-primary:hover {
  background-color: #5a6268 !important;
  border-color: #545b62 !important;
}

.btn-outline-primary {
  border-color: #6c757d;
  color: #6c757d;
}
.btn-outline-primary:hover {
  border-color: #5a6268 !important;
  background-color: #5a6268 !important;
  color: #ffffff !important;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #6c757d;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 724px;
  }
}

#snipcart {
  display: none !important;
}
