.venue-status-toggle {
  position: absolute;
  right: 4rem;
  margin-top: -3px;
  .MuiFormControlLabel-root {
    margin: 0;
  }
}

#event-dropzone .dropzone {
  height: 250px;
  width: 94%;
}

.MuiOutlinedInput-input {
  padding: 10px 16px !important;
}

.pagination a {
  line-height: 20px;
  height: 35px;
}

.venue-dropzone-wrapper .dropzone {
  margin: 0;
}
