#add-images-dropzone-wrapper {
  .dropzone {
    box-sizing: border-box;
    width: auto;
  }
}

.image-overlay {
  opacity: 0;
  border-radius: 12px;
  position: absolute;
  // FIXME Why is this necessary?
  //top: -90px;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  background: linear-gradient(180deg,rgba(33,43,54,.55),hsla(0,0%,100%,0));
}

.image-overlay:hover {
  opacity: 1;
}

.ReactModalPortal {
  position: absolute;
  z-index: 1060;
}
